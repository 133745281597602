const initialState = {
  affiliateAccessTokens: [],
  permissions: {
    isBusy: false,
    isBusyShortInfo: false,
    totalRecordsCount: undefined,
    records: [],
    isVisibleRemove: false,
    editable: undefined,
    functions: {
      isBusy: false,
      saveResult: undefined,
      list: [],
      isVisibleDelete: false,
    },
    roles: {
      allRoles: [],
      selectedRoles: [],
      saveResult: undefined,
    },
    likeAdmin: {
      list: [],
      isBusy: false,
      saveResult: undefined,
      isVisibleLikeAdmin: false,
    },
  },
  createPermission: {
    saveResult: undefined,
    isVisibleCreate: false,
  },
  languageUpdate: {
    isBusy: false,
    result: undefined,
  },
  deployReport: {
    isBusy: false,
    totalRecordsCount: undefined,
    records: [],
  },
  secondaryPartner: {
    isBusy: false,
    totalRecordsCount: undefined,
    records: [],
  },
};

export default initialState;
