import Toaster from '@geneui/components/Toaster';
import { Connection } from 'utils';

import { GET_CONFIG_FOR_POSTBACK, ADD_POSTBACK_CONFIG_ELEMENTS, DELETE_POSTBACK_CONFIG_ELEMENTS } from 'constants/api';
import { RESPONSE_SUCCESS_DELETE_INFO, RESPONSE_SUCCESS_INFO } from 'constants/defines';

export default {
  /* PostBack Config in deploy */
  getConfigPostback: () => Connection.post(GET_CONFIG_FOR_POSTBACK),
  deletePostbackConfigElements: async data => {
    const { result } = await Connection.post(DELETE_POSTBACK_CONFIG_ELEMENTS, data);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_DELETE_INFO);
    }

    return result;
  },
  addPostbackConfigElements: async data => {
    const { result } = await Connection.post(ADD_POSTBACK_CONFIG_ELEMENTS, data);

    if (result) {
      Toaster.success(RESPONSE_SUCCESS_INFO);
    }

    return result;
  },
};
