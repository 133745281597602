import { cloneDeep } from 'lodash';

import Connection from 'utils/connection';
import {
  GET_CONFIG,
  EDIT_CONFIG,
  CHANGE_SKIN_STATUS,
  GET_PARTNER_SKIN_TYPE,
  GET_PARTNER_PERMISSION_BY_ID,
  EDIT_PARTNER_PERMISSIONS,
} from 'constants/api';
import { SKIN_TYPES } from 'constants/defines';

export const getConfig = (partnerId, isAgent) => Connection.post(GET_CONFIG, { partnerId, isAgent });

export const getPartnerSkinType = partnerId => Connection.post(GET_PARTNER_SKIN_TYPE, { partnerId });

export const getPartnerPermissionById = data => Connection.post(GET_PARTNER_PERMISSION_BY_ID, data);

const updatePartner = partnerInfo => Connection.post(EDIT_CONFIG, { config: partnerInfo });
const changeSkinStatus = partnerStatus => Connection.post(CHANGE_SKIN_STATUS, partnerStatus);
const customCalculation = calculation => Connection.post(EDIT_PARTNER_PERMISSIONS, calculation);

export const updatePartnerAllInfo = async (partnerInfo, partnerStatus, calculation) => {
  return await Connection.all([
    partnerInfo ? updatePartner(partnerInfo) : null,
    partnerStatus ? changeSkinStatus(partnerStatus) : null,
    calculation ? customCalculation(calculation) : null,
  ]);
};

export const getAffiliateAccessTokens = async ({ state, effects }, partnerId) => {
  const { result } = await effects.deploy.getAffiliateAccessTokens(partnerId);
  if (result) {
    state.deploy = { ...state.deploy, affiliateAccessTokens: result };
  }
};

export const setAffiliateAccessToken = async ({ state, effects }, { partnerId, partnerAffiliateId }) => {
  if (
    state.deploy.affiliateAccessTokens.findIndex(accessTokens => accessTokens.affiliateId === partnerAffiliateId) !== -1
  ) {
    return;
  }
  const { result } = await effects.deploy.setAffiliateAccessToken(partnerId, partnerAffiliateId);
  if (result) {
    state.deploy.affiliateAccessTokens = [
      ...state.deploy.affiliateAccessTokens,
      { partnerId, affiliateId: partnerAffiliateId, accessToken: result },
    ];
  }
};

export const removeAffiliateAccessToken = async ({ state, effects }, affiliateId) => {
  const { result } = await effects.deploy.removeAffiliateAccessToken(affiliateId);
  if (result) {
    state.deploy.affiliateAccessTokens = state.deploy.affiliateAccessTokens.filter(
      accessToken => accessToken.affiliateId !== affiliateId
    );
  }
};

export const getPermissionsList = async ({ state, effects }, { filterParams }) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.isBusy = true;
  state.deploy = deploy;

  const { result } = await effects.deploy.getPermissionsList(filterParams);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.records = result.records;
    deploy.permissions.totalRecordsCount = result.totalRecordsCount;
  }

  deploy.permissions.isBusy = false;
  state.deploy = deploy;
};

export const removePermission = async ({ state, effects }, id) => {
  const result = await effects.deploy.removePermission(id);
  if (result) {
    state.deploy = {
      ...state.deploy,
      permissions: {
        isVisibleRemove: false,
        records: state.deploy.permissions.records.filter(item => item.id !== id),
      },
    };
  }
};

export const getRoles = async ({ state, effects }, id) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.isBusyShortInfo = true;
  state.deploy = deploy;

  const result = await effects.deploy.getRoles(id);

  deploy = cloneDeep(state.deploy);

  const { result: allRoles } = result[0];

  if (id) {
    const { result: selectedRoles } = result[1];

    deploy.permissions.roles.selectedRoles = selectedRoles ? selectedRoles : [];
  }

  deploy.permissions.roles.allRoles = allRoles
    ? allRoles.map(item => ({
        ...item,
        genericKey: item.genericKey === 'userRole' ? SKIN_TYPES.AFFILIATE : SKIN_TYPES.AGENT,
      }))
    : [];

  deploy.permissions.isBusyShortInfo = false;
  state.deploy = deploy;
};

export const addPermissionForRoles = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.roles.isBusy = true;
  deploy.permissions.roles.saveResult = undefined;
  state.deploy = deploy;

  const result = await effects.deploy.addPermissionForRoles(params);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.roles.saveResult = result;
  }

  deploy.permissions.roles.isBusy = false;
  state.deploy = deploy;
};

export const addPermission = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  deploy.createPermission.isBusy = true;
  deploy.createPermission.saveResult = undefined;
  state.deploy = deploy;

  const result = await effects.deploy.addPermission(params);

  deploy = cloneDeep(state.deploy);
  if (result) {
    deploy.createPermission.saveResult = result;
    deploy.createPermission.isVisibleCreate = false;
  }

  deploy.createPermission.isBusy = false;
  state.deploy = deploy;
};

export const getPermissionEditable = async ({ state, effects }, id) => {
  let deploy = cloneDeep(state.deploy);
  state.deploy = deploy;

  const { result } = await effects.deploy.getPermissionEditable({
    permissionId: id,
  });

  deploy = cloneDeep(state.deploy);

  deploy.permissions.editable = result ? result.editable : undefined;

  state.deploy = deploy;
};

export const setPermissionEditable = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  state.deploy = deploy;

  const result = await effects.deploy.setPermissionEditable(params);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.editable = params.editable;
  }

  state.deploy = deploy;
};

export const getPermissionsFunctions = async ({ state, effects }, id) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.functions.isBusy = true;
  state.deploy = deploy;

  const { result } = await effects.deploy.getPermissionsFunctions({
    permissionId: id,
  });

  deploy = cloneDeep(state.deploy);

  deploy.permissions.functions.list = result
    ? result.map(item => ({
        key: item.functionName,
        value: item.className,
        readonly: true,
      }))
    : [];

  deploy.permissions.functions.isBusy = false;
  state.deploy = deploy;
};

export const addPermissionsFunctions = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.functions.isBusy = true;
  deploy.permissions.functions.saveResult = undefined;
  state.deploy = deploy;

  const result = await effects.deploy.addPermissionsFunctions(params);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.functions.saveResult = result;
  }

  deploy.permissions.functions.isBusy = false;
  state.deploy = deploy;
};

export const removePermissionsFunctions = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.functions.isBusy = true;
  deploy.permissions.functions.result = undefined;
  state.deploy = deploy;

  const result = await effects.deploy.removePermissionsFunctions(params);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.functions.result = result;
    deploy.permissions.functions.isVisibleDelete = false;
  }

  deploy.permissions.functions.isBusy = false;
  state.deploy = deploy;
};

export const getPermissionsRolesTypes = async ({ state, effects }, id) => {
  let deploy = cloneDeep(state.deploy);
  deploy.permissions.likeAdmin.isBusy = true;
  state.deploy = deploy;

  const { result } = await effects.deploy.getPermissionsRolesTypes({
    permissionId: id,
  });

  deploy = cloneDeep(state.deploy);

  deploy.permissions.likeAdmin.list = result ? result : [];

  deploy.permissions.likeAdmin.isBusy = false;
  state.deploy = deploy;
};

export const setPermissionsRolesTypes = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);

  deploy.permissions.likeAdmin.isBusy = true;
  deploy.permissions.likeAdmin.saveResult = undefined;
  state.deploy = deploy;

  const result = await effects.deploy.setPermissionsRolesTypes(params);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.permissions.likeAdmin.isVisibleLikeAdmin = false;
    deploy.permissions.likeAdmin.saveResult = result;
  }

  deploy.permissions.likeAdmin.isBusy = false;
  state.deploy = deploy;
};

export const removePermissionsRolesTypes = async ({ state, effects }, params) => {
  const result = await effects.deploy.removePermissionsRolesTypes(params);

  if (result) {
    state.deploy.permissions = {
      ...state.deploy.permissions,
      likeAdmin: {
        list: state.deploy.permissions.likeAdmin.list.filter(item => Number(item.role) !== Number(params.role)),
      },
    };
  }
};

export const setOptionalFunction = ({ state }, data) => {
  state.deploy.permissions.functions = {
    ...state.deploy.permissions.functions,
    list: data,
  };
};

export const setIsVisibleCreate = ({ state }, data) => {
  state.deploy.createPermission = {
    ...state.deploy.createPermission,
    isVisibleCreate: data,
  };
};

export const setIsVisibleDelete = ({ state }, data) => {
  state.deploy.permissions.functions = {
    ...state.deploy.permissions.functions,
    isVisibleDelete: data,
  };
};

export const setIsVisibleRemove = ({ state }, data) => {
  state.deploy.permissions = {
    ...state.deploy.permissions,
    isVisibleRemove: data,
  };
};

export const setIsVisibleLikeAdmin = ({ state }, data) => {
  state.deploy.permissions.likeAdmin = {
    ...state.deploy.permissions.likeAdmin,
    isVisibleLikeAdmin: data,
  };
};

export const updateLanguageTranslation = async ({ state, effects }, language) => {
  let deploy = cloneDeep(state.deploy);
  deploy.languageUpdate.isBusy = true;
  state.deploy = deploy;
  deploy = cloneDeep(state.deploy);
  try {
    const { result } = await effects.deploy.updateLanguageTranslations(language);
    state.deploy.languageUpdate = result;
  } finally {
    deploy.languageUpdate.isBusy = false;
    state.deploy = deploy;
  }
};

export const getDeployPartnerReport = async ({ state, effects }, params) => {
  let deploy = cloneDeep(state.deploy);
  deploy.deployReport.isBusy = true;
  state.deploy = deploy;

  const { result } = await effects.deploy.getPartnerReport({ ...params });

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.deployReport.records = result.records;
    deploy.deployReport.totalRecordsCount = result.totalRecordsCount;
  }

  deploy.deployReport.isBusy = false;
  state.deploy = deploy;
  return { result };
};

export const getSecondaryPartnersList = async ({ state, effects }, { filterParams }) => {
  let deploy = cloneDeep(state.deploy);
  deploy.secondaryPartner.isBusy = true;
  state.deploy = deploy;

  const { result } = await effects.deploy.getSecondaryPartnersList(filterParams);

  deploy = cloneDeep(state.deploy);

  if (result) {
    deploy.secondaryPartner.records = result.records;
    deploy.secondaryPartner.totalRecordsCount = result.totalRecordsCount;
  }

  deploy.secondaryPartner.isBusy = false;
  state.deploy = deploy;
};

export const editDeployPartnerReport = ({ state, effects }, params) => effects.deploy.setPartnerReport(params);
