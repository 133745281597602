import initialProfile from './profile';
import initialPostBack from './postback';
import initialDeploy from './deploy';
import initialAffiliates from './affiliates';

const initialState = {
  deploy: initialDeploy,
  affiliatesList: [],
  profile: initialProfile,
  affiliates: initialAffiliates,
  postBack: initialPostBack,
};

export default initialState;
