import { cloneDeep } from 'lodash';

export default {
  getPostbackChangeLog: async ({ state, effects }, { filterParams }) => {
    let profile = cloneDeep(state.profile);
    profile.logs.postbackChangeLog.isBusy = true;
    state.profile = profile;

    const { result } = await effects.profile.getPostbackChangeLog(filterParams);

    profile = cloneDeep(state.profile);
    if (result) {
      profile.logs.postbackChangeLog.records = result.records;
      profile.logs.postbackChangeLog.totalRecordsCount = result.totalRecordsCount;
    }
    profile.logs.postbackChangeLog.isBusy = false;
    state.profile = profile;
  },
};
