import React from 'react';
import { FormProvider, Form } from 'react-hook-form';

const FormContainer = ({ children, methods, ...restProps }) => {
  return (
    <FormProvider {...methods}>
      <Form {...restProps}>{children}</Form>
    </FormProvider>
  );
};

export default FormContainer;
