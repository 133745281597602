import { cloneDeep } from 'lodash';

import Connection from 'utils/connection';
import * as api from 'constants/api';
import { dropDownDataFormatter } from 'utils';

const getPaymentSystems = affiliateId => Connection.post(api.GET_AFFILIATE_PAYMENT_SYSTEMS, { affiliateId });

const getDefaultWithdraw = affiliateId => Connection.post(api.GET_AFFILIATE_DEFAULT_WITHDRAW, { affiliateId });

export const getWithdrawPaymentAction = async affiliateId => {
  const result = await Connection.all([getPaymentSystems(affiliateId), getDefaultWithdraw(affiliateId)]);
  const [paymentResult, withdrawResult] = result;
  const withdrawData = withdrawResult.result[0];
  const paymentList = paymentResult.result;

  return { paymentList, withdrawData };
};

const getAffiliatesByHierarchies = async ({ state, effects }, { filterParams, name }) => {
  let affiliate = cloneDeep(state.affiliates);

  if (!affiliate.affiliateHierarchies[name]) {
    affiliate.affiliateHierarchies[name] = {};
  }

  affiliate.affiliateHierarchies[name].isBusy = true;
  state.affiliates = affiliate;

  try {
    const { needReset: omit, ...data } = filterParams;

    const { result } = await effects.affiliates.getAffiliatesByHierarchies(data);

    affiliate = cloneDeep(state.affiliates);
    if (result) {
      affiliate.affiliateHierarchies[name].records = [
        ...(filterParams.needReset ? [] : state.affiliates.affiliateHierarchies[name].records),
        ...dropDownDataFormatter(result.records, 'affiliateId', 'username'),
      ];
      affiliate.affiliateHierarchies[name].totalRecordsCount = Number(result.totalRecordsCount);
      state.affiliates = affiliate;
    }
  } finally {
    affiliate = cloneDeep(state.affiliates);

    affiliate.affiliateHierarchies[name].isBusy = false;
    state.affiliates = affiliate;
  }
};

const setAffiliatesByHierarchies = ({ state }, { data, name }) => {
  state.affiliates.affiliateHierarchies[name] = {
    ...(state.affiliates.affiliateHierarchies[name] ?? {}),
    records: data,
  };
};

export default {
  getAffiliatesByHierarchies,
  setAffiliatesByHierarchies,
};
