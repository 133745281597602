import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import FormContainer from 'components/FormContainer';
import FormInput from 'components/FormContainer/FormInput';

import { useTranslator } from 'utils/translator';
import useResetPassword from 'utils/hooks/useResetPassword';
import { setDefaultPage } from 'reduxStore/slices/settingsSlice';

const ResetPassword = ({ type, onClose }) => {
  const { t } = useTranslator();
  const dispatch = useDispatch();

  const { isLoading, repeatedPassword, isValid, methods, onApply } = useResetPassword(type, onClose);

  const sharedProps = {
    required: true,
    type: 'password',
    appearance: 'minimal',
    cornerRadius: 'smooth-radius',
    className: 'resetPasswordFormField',
  };

  const handleApply = () => {
    onApply();
    dispatch(setDefaultPage(undefined));
  };

  return (
    <Modal
      visible
      withPortal
      isBusy={isLoading}
      onApply={handleApply}
      onCancel={onClose}
      cancelText={type ? 'cancel' : 'log-out'}
      background="transparent"
      appearance="compact"
      title="change-password"
      position="top"
      isOkActive={isValid}
      size="content-size"
      okText="Save"
    >
      <FormContainer methods={methods} className="resetPassWrapper-bc form">
        <FormInput
          dataKey="oldPassword"
          label={t('current-password')}
          placeholder={t('current-password')}
          {...sharedProps}
        />
        <FormInput
          validationKey="password"
          label={t('new-password')}
          placeholder={t('new-password')}
          dataKey="newPassword"
          {...sharedProps}
        />
        <FormInput
          isValid={repeatedPassword}
          validationKey="confirmPasswordLogin"
          label={t('confirm-password')}
          placeholder={t('confirm-password')}
          dataKey="repeatedPassword"
          {...sharedProps}
        />
      </FormContainer>
    </Modal>
  );
};

ResetPassword.propTypes = {
  type: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ResetPassword;
