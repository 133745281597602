import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormInput from 'components/FormContainer/FormInput';
import FormField from 'components/FormField';

import { useTranslator, noop } from 'utils';
import isMobile from 'utils/isMobile';
import { addPlusPrefixToCountyCode } from 'utils/helpers';
import { useGeneric } from 'utils/hooks/useGeneric';

const PhoneNumberFields = ({
  readOnly,
  required,
  isAccount,
  inputSize,
  phoneState,
  appearance,
  addUserModal,
  cornerRadius,
  labelAppearance,
  changePhoneState,
  endAdornment,
}) => {
  const { t } = useTranslator();
  const { countries } = useGeneric();

  const sharedProps = {
    readOnly,
    inputSize,
    appearance,
    cornerRadius,
    labelAppearance,
  };

  const myCountryCellCode = useMemo(
    () =>
      addPlusPrefixToCountyCode(countries).find(country => country.code === phoneState.cellPhoneCountryCode)?.dialCode,
    [countries, phoneState.cellPhoneCountryCode]
  );

  useEffect(() => {
    if (!phoneState.cellPhone && phoneState.countryCode) {
      changePhoneState({ cellPhoneCountryCode: phoneState.countryCode });
    }
  }, [phoneState.cellPhone, phoneState.countryCode]);

  const Input = isAccount ? FormInput : FormField;

  return (
    <div
      className={classNames('restorePhoneNumberContainer', {
        addUserModal: addUserModal,
        addUserMobile: isMobile(),
      })}
    >
      <div className="countryCodeField">
        <Input
          type="dropdown"
          data={addPlusPrefixToCountyCode(countries)}
          required={required}
          dataKey="cellPhoneCountryCode"
          labelKey="dialCode"
          valueKey="code"
          tooltipText={myCountryCellCode}
          label={t('code')}
          value={phoneState.cellPhoneCountryCode}
          onChange={changePhoneState}
          clearable={false}
          flexibility="content-size"
          {...sharedProps}
        />
      </div>
      <div className="cellPhoneNumberField">
        <Input
          id="phoneNumber"
          type="text"
          required={required}
          dataKey="cellPhone"
          value={phoneState.cellPhone}
          onChange={changePhoneState}
          validationKey="phoneNumber"
          label={t('Phone-Number')}
          flexibility="full-width"
          placeholder={t('mobile')}
          endAdornment={endAdornment}
          {...sharedProps}
        />
      </div>
    </div>
  );
};

PhoneNumberFields.propsTypes = {
  endAdornment: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  addUserModal: PropTypes.bool,
  inputSize: PropTypes.string,
  appearance: PropTypes.string,
  cornerRadius: PropTypes.string,
  labelAppearance: PropTypes.string,
  phoneState: PropTypes.object.isRequired,
  changePhoneState: PropTypes.func,
};

PhoneNumberFields.defaultProps = {
  readOnly: false,
  required: false,
  inputSize: 'big',
  addUserModal: false,
  appearance: 'outline',
  labelAppearance: 'none',
  cornerRadius: 'smooth-radius',
  changePhoneState: noop,
};

export default PhoneNumberFields;
