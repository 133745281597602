import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import ExtendedInput from '@geneui/components/ExtendedInput';
import Dropdown from '@geneui/components/Dropdown';
import DatePickerInput from '@geneui/components/DatePickerInput';
import Radio from '@geneui/components/Radio';
import Switcher from '@geneui/components/Switcher';
import Checkbox from '@geneui/components/Checkbox';
import { Icon } from '@geneui/components';

import FileUploader from 'components/FileUploader';

import { dateFormat } from 'configs';
import { formatNumber, useTranslator } from 'utils';
import { DROPDOWN_SEARCH_VISIBLE_MAX_COUNT, FORM_FIELD_TYPES } from 'constants/defines';
import ValidationRules from 'constants/validationRules';
import mask from 'utils/mask';

const flatFormProps = {
  inputSize: 'small',
  appearance: 'minimal',
  labelAppearance: 'title',
  cornerRadius: 'smooth-radius',
};

const regularFormProps = {
  labelAppearance: 'swap',
};

const FormInput = ({
  type,
  dataKey,
  onChange,
  flatForm,
  valueKey,
  formatted,
  fullObject,
  validationKey,
  isMultiSelect,
  maskPattern,
  clearable,
  required,
  isValid,
  value,
  ...restProps
}) => {
  const { t } = useTranslator();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [showPassword, setShowPassword] = useState(false);

  const isDataMore = restProps.hasSearch ?? restProps.data?.length > DROPDOWN_SEARCH_VISIBLE_MAX_COUNT;

  const sharableProps = {
    clearable,
    required,
    isValid: !errors[dataKey] && isValid !== false,
    errorText: t(
      required && errors[dataKey]?.type === 'required' ? 'this-field-is-required' : errors[dataKey]?.message
    ),
  };

  const handleOnChange = (e, field) => {
    let value =
      e && e.target
        ? type === 'switcher' || type === 'checkbox'
          ? e.target.checked
          : e.target.value
        : (type === 'dropdown' && !fullObject && !isMultiSelect) || type === 'radio'
        ? e && e[valueKey]
        : type === 'datepicker'
        ? e
          ? moment(e).format(dateFormat)
          : undefined
        : type === 'dropdown' && !fullObject && isMultiSelect
        ? e.map(item => item[valueKey])
        : e;

    if (maskPattern) {
      value = typeof maskPattern === 'function' ? maskPattern(value) : mask(value, maskPattern);
    }

    if (onChange) {
      onChange({
        [dataKey]: value,
      });
    }

    return field.onChange(value);
  };

  const onChangeFile = ({ value }, field) => {
    if (onChange) {
      onChange({
        [dataKey]: value,
      });
    }
    return field.onChange(value);
  };

  useEffect(() => {
    if (value) {
      setValue(dataKey, value);
    }
  }, [value, dataKey]);

  return (
    <Controller
      name={dataKey}
      control={control}
      rules={{ ...ValidationRules[validationKey], required }}
      render={({ field }) => {
        switch (type) {
          case 'dropdown':
            return isMultiSelect ? (
              <Dropdown
                isMultiSelect
                valueKey={valueKey}
                {...field}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                {...restProps}
                onChange={e => handleOnChange(e, field)}
                hasSearch={isDataMore}
              />
            ) : (
              <Dropdown
                valueKey={valueKey}
                {...field}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                {...restProps}
                onChange={e => handleOnChange(e, field)}
                hasSearch={isDataMore}
              />
            );
          case 'radio':
            return <Radio {...field} {...restProps} onChange={e => handleOnChange(e, field)} />;
          case 'checkbox':
            return (
              <Checkbox
                labelPosition="top"
                checked={field.value}
                {...field}
                {...sharableProps}
                onChange={e => handleOnChange(e, field)}
                {...restProps}
              />
            );
          case 'datepicker':
            return (
              <DatePickerInput
                format={dateFormat}
                {...field}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                onChange={e => handleOnChange(e, field)}
                {...restProps}
              />
            );
          case 'switcher':
            return (
              <Switcher
                labelPosition="top"
                {...field}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                onChange={e => handleOnChange(e, field)}
                {...restProps}
              />
            );
          case 'fileUploader':
            return <FileUploader {...field} {...sharableProps} {...restProps} onChange={e => onChangeFile(e, field)} />;
          case 'password':
            return (
              <ExtendedInput
                {...field}
                type={showPassword ? 'text' : 'password'}
                value={field.value && formatted ? formatNumber(field.value) : field.value}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                onChange={e => handleOnChange(e, field)}
                endAdornment={
                  <Icon
                    disabled={restProps?.disabled || restProps?.readOnly}
                    className="showPassword"
                    type={showPassword ? 'bc-icon-activate-48' : 'bc-icon-inactivate-48'}
                    onClick={() => setShowPassword(prev => !prev)}
                  />
                }
                {...restProps}
              />
            );
          default:
            return (
              <ExtendedInput
                {...field}
                type={type}
                value={field.value && formatted ? formatNumber(field.value) : field.value}
                {...sharableProps}
                {...(flatForm ? flatFormProps : regularFormProps)}
                onChange={e => handleOnChange(e, field)}
                {...restProps}
              />
            );
        }
      }}
    />
  );
};

FormInput.propTypes = {
  flatForm: PropTypes.bool,
  required: PropTypes.bool,
  formatted: PropTypes.bool,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  fullObject: PropTypes.bool,
  valueKey: PropTypes.string,
  clearable: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  validationKey: PropTypes.string,
  type: PropTypes.oneOf(FORM_FIELD_TYPES),
};

FormInput.defaultProps = {
  required: false,
  flatForm: false,
  dataKey: 'value',
  formatted: false,
  valueKey: 'value',
  clearable: true,
  fullObject: false,
  isMultiSelect: false,
  type: FORM_FIELD_TYPES[0],
};

export default FormInput;
