import { useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import * as DEFINES from 'components/Commission/CreateCommission/defines';

import {
  ALL_DATA,
  USER_TYPES,
  USER_STATUS,
  DEFAULT_CURRENCY,
  PERMISSION_STATUS,
  USER_MAIN_CURRENCY,
  CONDITIONS_ACTIONS,
  USER_REPORT_CURRENCY,
  COMMISSION_PLAN_PLAYER_COUNT,
  COMMISSION_PlAN_NET_DEPOSIT,
  AFFILIATES_ROLE,
  DEFAULT_COUNTRY,
  PRODUCT_LIST,
  PRODUCT_KEYS,
} from 'constants/defines';

import isMobile from '../isMobile';
import { useGeneric } from './useGeneric';
import { useConfig } from './useConfig';
import { useApp } from './useApp';

const mobileDeniedPermission = new Set([
  //Configuration
  'bonus',
  'social',
  'metaTags',
  'postBack',
  'permissions',
  'smsTemplates',
  'mailTemplates',
  'editDictionary',
  'paymentMethods',
  'commissionPlan',
  'deployPostbackConfig',
  'skinPersonalisation',
  'registrationFields',
  'layoutElementsGet',
]);

const useHelpers = () => {
  const { loginAsUser } = useApp();

  const generic = useGeneric();
  const {
    config: { partnerId, isMultiSkin },
    mainCurrency: defaultCurrency,
  } = useConfig();

  const {
    currentUser,
    permissionsList,
    currentUser: { status, isAgent, affiliateId, role },
  } = useApp();

  const { PARTNER_ADMIN } = AFFILIATES_ROLE;

  const getConditionByType = (type, forCpa = false) => {
    const { commissionConditionsCpa, commissionConditions } = generic;
    const conditions = forCpa ? commissionConditionsCpa : commissionConditions;

    return conditions.find(({ id }) => id === parseInt(type)) || {};
  };

  const getDefaultRevenueShareModel = revenueShareType => {
    const currentModel =
      revenueShareType === COMMISSION_PLAN_PLAYER_COUNT ? DEFINES.PLAYER_COUNT_MODEL : DEFINES.REVENUE_SHARE_MODEL;
    const model = { name: '', planId: revenueShareType };
    const { brands, products: genericProducts } = generic;
    const structure = {};
    const products =
      revenueShareType === COMMISSION_PlAN_NET_DEPOSIT
        ? [{ id: PRODUCT_LIST.SPORTS_BOOK, label: PRODUCT_KEYS.SPORTS_BOOK }]
        : genericProducts;

    for (let i = 0; i < brands.length; i++) {
      structure[brands[i].id] = {
        commissions: [...products, { id: ALL_DATA, label: 'ranges-for-all-products' }].map(({ id, label }) => ({
          productName: label,
          productId: parseInt(id),
          rows: JSON.parse(JSON.stringify(currentModel)),
        })),
        conditions: [],
      };
    }

    return { ...model, structure };
  };

  const getCpaConditionsModel = () => {
    const { commissionConditionsCpa } = generic;

    return {
      amount: '',
      type: commissionConditionsCpa[0].id,
      action: CONDITIONS_ACTIONS[0].value,
    };
  };

  const getCpaPaymentModel = () => {
    return {
      level: 1,
      payment: null,
      lossMin: 0,
      lossMax: null,
    };
  };

  const getDefaultCpaModel = () => ({
    name: '',
    payment: '',
    structure: !isMultiSkin
      ? {
          [partnerId]: {
            isVerified: 0,
            products: [],
            conditions: [getCpaConditionsModel()],
            paymentConditions: { [DEFAULT_COUNTRY]: [getCpaPaymentModel()] },
          },
        }
      : {},
    period: {},
  });

  const isUserPassive = useMemo(
    () => parseInt(status) === USER_STATUS.PASSIVE || parseInt(loginAsUser?.status) === USER_STATUS.PASSIVE,
    [loginAsUser?.status, status]
  );

  const isAdminPasswordPage = useMemo(
    () => isUserPassive && Number(role) === PARTNER_ADMIN,
    [PARTNER_ADMIN, isUserPassive, role]
  );

  const isPermitted = useCallback(
    permissionName => {
      const permitted =
        !isUserPassive && !isAdminPasswordPage && permissionsList[permissionName] === PERMISSION_STATUS.ACCEPTED;

      return !isMobile() ? permitted : permitted && !mobileDeniedPermission.has(permissionName);
    },
    [isAdminPasswordPage, isUserPassive, permissionsList]
  );

  const isAgentSystem = () => isAgent === USER_TYPES.AGENT;

  const isSelfAndPermitted = (isSelf, permissionName) => isSelf && isPermitted(permissionName);

  const isPerProductsCpaConditions = conditions =>
    conditions.every(val => {
      const { type } = val;
      const { commissionConditionsCpa } = generic;
      const { isProducts } = commissionConditionsCpa.find(({ id }) => id === parseInt(type));

      return !isProducts;
    });

  const getCurrency = type => {
    let currencyCode;
    const { currencies } = generic;
    const { reportCurrency, mainCurrency } = !isEmpty(loginAsUser) ? loginAsUser : currentUser;

    switch (type) {
      case DEFAULT_CURRENCY:
        currencyCode = defaultCurrency.iso;
        break;
      case USER_REPORT_CURRENCY:
        currencyCode = reportCurrency;
        break;
      case USER_MAIN_CURRENCY:
        currencyCode = mainCurrency;
        break;
      default:
        currencyCode = '';
    }

    return (
      currencies.find(({ id }) => id === currencyCode) || {
        id: mainCurrency,
        label: mainCurrency,
        code: mainCurrency,
      }
    );
  };

  const getCurrencySymbolByCode = currencyCode => {
    const { currencies } = generic;
    const { label } = currencies.find(({ id }) => id === currencyCode) || {};

    return label || '';
  };

  const isTransferAgentPermitted = useMemo(
    () => isPermitted('bonusToAgent') || isPermitted('depositToAgent') || isPermitted('withdrawFromAgent'),
    [isPermitted]
  );

  const isSelfUser = userId => parseInt(affiliateId) === parseInt(userId);

  return {
    isSelfUser,
    getCurrency,
    isPermitted,
    isAgentSystem,
    isUserPassive,
    isSelfAndPermitted,
    getConditionByType,
    getDefaultCpaModel,
    getCpaPaymentModel,
    isAdminPasswordPage,
    getCpaConditionsModel,
    getCurrencySymbolByCode,
    isTransferAgentPermitted,
    isPerProductsCpaConditions,
    getDefaultRevenueShareModel,
  };
};

export default useHelpers;
