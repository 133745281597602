const initialPostBack = {
  /* PostBack Config in deploy */
  postBackConfig: {
    isBusy: false,
    isVisibleDelete: false,
    isVisibleCreate: false,
    resultParams: [],
  },

  testPostbackLog: {
    isBusy: false,
  },
};

export default initialPostBack;
