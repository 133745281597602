import { useForm } from 'react-hook-form';

import Toaster from '@geneui/components/Toaster';
import { useTranslator } from 'utils/translator';
import { useApp } from 'utils/hooks/useApp';
import { usePasswordChangeMutation } from 'reduxStore/services/userApi';

const defaultProps = {
  oldPassword: null,
  newPassword: null,
  repeatedPassword: null,
};

const useResetPassword = (type, onClose) => {
  const { t } = useTranslator();
  const {
    currentUser: { isAgent },
  } = useApp();
  const methods = useForm({
    mode: 'all',
    defaultValues: defaultProps,
  });

  const [passwordChange, { isLoading }] = usePasswordChangeMutation();

  const {
    watch,
    formState: { isValid },
    reset,
    handleSubmit,
  } = methods;

  const handelCancel = () => {
    onClose();
    methods.reset(defaultProps);
  };

  const passwordChangeHandler = async password => {
    const { data } = await passwordChange({ type, isAgent, password });

    if (data?.result) {
      Toaster.success({
        title: t('success'),
        message: t('saved'),
        iconProps: {
          isFilled: true,
        },
      });
      handelCancel();
    }
  };

  const newPassword = watch('newPassword');
  const repeatedPassword = watch('repeatedPassword');

  return {
    isLoading,
    methods,
    isValid,
    onReset: () => reset(defaultProps),
    repeatedPassword: repeatedPassword && newPassword === repeatedPassword,
    onApply: () => handleSubmit(passwordChangeHandler)(),
  };
};

export default useResetPassword;
