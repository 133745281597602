import { cloneDeep } from 'lodash';

/* PostBack Config in deploy */
const getConfigForPostback = async ({ state, effects }) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBackConfig.isBusy = true;
  state.postBack = postBack;

  const { result } = await effects.postBack.getConfigPostback();

  postBack = cloneDeep(state.postBack);

  postBack.postBackConfig.resultParams = (result.params || []).map(key => ({
    paramName: key,
    isEmpty: false,
  }));

  postBack.postBackConfig.isBusy = false;
  state.postBack = postBack;
};

const setPostBackConfigParams = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    resultParams: data,
  };
};

const setIsVisiblePostbackDelete = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    isVisibleDelete: data,
  };
};

const setIsVisiblePostbackCreate = ({ state }, data) => {
  state.postBack.postBackConfig = {
    ...state.postBack.postBackConfig,
    isVisibleCreate: data,
  };
};

const deleteParamsPostbackConfig = async ({ state, effects }, params) => {
  let postBack = cloneDeep(state.postBack);
  const elements = postBack.postBackConfig.resultParams[params].paramName;
  state.postBack = postBack;

  const result = await effects.postBack.deletePostbackConfigElements({
    elements,
  });
  postBack = cloneDeep(state.postBack);

  if (result) {
    postBack.postBackConfig.resultParams = postBack.postBackConfig.resultParams.filter((_, i) => i !== params);
    postBack.postBackConfig.isVisibleDelete = false;
  }
  state.postBack = postBack;
};

const addPostbackConfig = async ({ state, effects }, params) => {
  let postBack = cloneDeep(state.postBack);
  postBack.postBackConfig.isBusy = true;
  state.postBack = postBack;

  const result = await effects.postBack.addPostbackConfigElements({
    elements: params,
  });

  postBack = cloneDeep(state.postBack);
  if (result) {
    postBack.postBackConfig.resultParams.push({
      paramName: params,
      isEmpty: false,
    });

    postBack.postBackConfig.isVisibleCreate = false;
  }
  postBack.postBackConfig.isBusy = false;
  state.postBack = postBack;
};

export default {
  getConfigForPostback,
  deleteParamsPostbackConfig,
  setPostBackConfigParams,
  setIsVisiblePostbackDelete,
  setIsVisiblePostbackCreate,
  addPostbackConfig,
};
